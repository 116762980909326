







import { Component, Vue, Prop } from 'vue-property-decorator';
import EventsDayPopup from '@/modules/events/components/events-manager/events-day-popup.vue';

@Component({
    components: { EventsDayPopup },
})
export default class EventsDayModalPage extends Vue {
    @Prop({ required: true })
    private day!: number;
}
