
























import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import EventsDayList from '@/modules/events/components/events-day-list.vue';
import { Inject } from 'inversify-props';
import { Component, Vue, Prop } from 'vue-property-decorator';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';

@Component({
    components: {
        ModalWrapper,
        LoaderWrapper,
        PopupEventsContainer,
        EventsDayList,
    },
})

export default class EventsDayPopup extends Vue {
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    @Prop({ required: true })
    private day!: number;

    get holidayEvents() {
        return this.monthEvents.monthEvents[this.day] ? this.monthEvents.monthEvents[this.day].holiday : [];
    }

    get myEvents() {
        return this.monthEvents.monthEvents[this.day] ? this.monthEvents.monthEvents[this.day].my : [];
    }

    get suggestedEvents() {
        return this.monthEvents.monthEvents[this.day] ? this.monthEvents.monthEvents[this.day].suggested : [];
    }

    get chainEvents() {
        return this.monthEvents.monthEvents[this.day] ? this.monthEvents.monthEvents[this.day].chain : [];
    }

    get monthEvents() {
        return this.eventsManagerService.getEventsByMonth();
    }

    closeModal() {
        const targetName = this.$route.name!.split('.');
        targetName.pop();

        this.$router.push({
            name: targetName.join('.'),
            params: {
                ...this.$route.params,
            },
        });
    }
}
